import { mapperBlockLogoBar, mapperBlockRelatedContent } from '../../../core/data-layer/blocks/mappers'
import { mapperGlobalDataTheme } from '../../../core/data-layer/global-data/mappers'
import {
  mapperModuleInternalVideo,
  mapperModuleMedia,
  mapperModuleNavigationLink
} from '../../../core/data-layer/modules/mappers'
import type { MapperOptions } from '../../../core/types'
import { mapperBlockCampaignsThankYouContent, mapperBlockLandingLogoBar } from '../mappers'

const relatedContentCardsImageOptions = {
  resize: true,
  lazy: true,
  sizes: {
    small: { width: 272, height: 153, aspectRatio: '16/9' },
    medium: { width: 576, height: 324, aspectRatio: '16/9' },
    large: { width: 576, height: 324, aspectRatio: '16/9' }
  }
}

/**
 * Page Campaigns Detail Awareness Mapper
 * Normalizes data to be used in campaigns detail awareness pages
 */
export const mapperContentKnowledgeHubCampaignsGuide = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  if (data.lazy) {
    return data
  }

  const headerImageOptionsWithZoom = {
    ...options.imageOptions,
    zoom: data.fpZoom
  }

  const headerDesktopImageOptionsWithZoom = {
    ...options.imageOptions,
    zoom: data.fpZoomDesktop
  }

  return {
    sys: data.sys,
    headerLink: mapperModuleNavigationLink(data.headerLink, options.getUrl),
    secondaryHeaderLink: mapperModuleNavigationLink(data.secondaryHeaderLink, options.getUrl),
    headerEyebrow: data.headerEyebrow || '',
    headerSubtitle: data.headerSubtitle || null,
    hasAnimatedHero: data.hasAnimatedHero,
    headerImage:
      data.headerImage && data.headerImage.contentType === 'ModuleMedia'
        ? mapperModuleMedia(data.headerImage, headerImageOptionsWithZoom)
        : null,
    desktopHeaderImage:
      data.desktopHeaderImage && data.desktopHeaderImage.contentType === 'ModuleMedia'
        ? mapperModuleMedia(data.desktopHeaderImage, headerDesktopImageOptionsWithZoom)
        : null,
    headerVideo:
      data.headerImage && data.headerImage.contentType === 'ModuleInternalVideo'
        ? mapperModuleInternalVideo(data.headerImage, {})
        : null,
    desktopHeaderVideo:
      data.desktopHeaderImage && data.desktopHeaderImage.contentType === 'ModuleInternalVideo'
        ? mapperModuleInternalVideo(data.desktopHeaderImage, {})
        : null,
    formId: data.formId,
    showFormInHeader: data.showFormInHeader,
    formAnchorLabel: data.formAnchorLabel,
    formTitle: data.formTitle,
    formDescription: data.formDescription,
    formSubmitLabel: data.formSubmitLabel,
    formSuccessRedirectPage: mapperModuleNavigationLink(data.formSuccessRedirectPage, options.getUrl!),
    logoBar: mapperBlockLogoBar(data.logoBar, options),
    landingLogoBar: mapperBlockLandingLogoBar(data.logoBar, options),
    // This field will be mapped in the asyncData because otherwise the query will become too big
    contentBlocks: [],
    relatedContent: mapperBlockRelatedContent(data.relatedContent, {
      ...options,
      imageOptions: relatedContentCardsImageOptions
    }),
    thankYouContent: mapperBlockCampaignsThankYouContent(data.thankYouContent, options),
    heroTheme: mapperGlobalDataTheme(data.heroTheme)?.theme || null
  }
}
