import mapperBlockSocialShare from '../../../../core/data-layer/blocks/block-social-share/mapper-block-social-share'
import { mapperBlockRelatedLinks, mapperBlockSimpleRelatedArticles } from '../../mappers'
import { mapperModuleMedia, mapperModuleMediaCard, mapperModuleVideo } from '../../../../core/data-layer/modules/mappers'
import imageSizes from '../../../../core/constants/imageSizes'
import { mapperKnowledgeHubArticleSummary } from '../../../../core/data-layer/knowledge-hub/summary/mapper-knowledge-hub-article-summary'
import type { MapperOptions } from '../../../../core/types'

/**
 * Block quote mapper
 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    const mapperImageSizes = imageSizes.columns12_169

    return {
        id: data.sys?.id || '',
        title: data.title || '',
        navigationTitle: data.navigationTitle || '',
        image: (data.media?.contentType === 'ModuleMedia')
            ? mapperModuleMedia(data.media, {
                resize: true,
                sizes: mapperImageSizes
            })
            : null,
        video: (data.media?.contentType === 'ModuleVideo' || data.media?.contentType === 'ModuleInternalVideo')
            ? mapperModuleVideo(data.media, {
                resize: true,
                sizes: mapperImageSizes
            })
            : null,
        text: data.text || null,
        sidebarItem:
        {
            contentType: data.sidebarItem?.contentType,
            ...data.sidebarItem?.contentType === 'BlockSocialShare' ? mapperBlockSocialShare(data.sidebarItem, options) : null,
            ...data.sidebarItem?.contentType === 'BlockRelatedLinks' ? mapperBlockRelatedLinks(data.sidebarItem, options.getUrl) : null,
            ...data.sidebarItem?.contentType === 'BlockSimpleRelatedArticles' ? mapperBlockSimpleRelatedArticles(data.sidebarItem, options) : null,
            ...data.sidebarItem?.contentType === 'PageKnowledgeHubDetail' ? mapperKnowledgeHubArticleSummary(data.sidebarItem, options) : null,
            ...data.sidebarItem?.contentType === 'ModuleMediaCard' ? mapperModuleMediaCard(data.sidebarItem, options) : null
        }
    }
}
