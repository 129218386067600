import { gql } from 'graphql-tag'
import { fragmentBlockFaqGroup, fragmentBlockQuote, fragmentBlockCallToActionWithMedia } from '../blocks/fragments'
import {
  fragmentModuleMedia,
  fragmentModuleVideo,
  fragmentModuleNavigationLink,
  fragmentModuleTable,
  fragmentModuleCodeSnippet,
  fragmentModuleInternalVideo
} from '../modules/fragments'
import { fragmentPageKnowledgeHubDetail } from '../../../knowledge-hub/data-layer/fragments'

/**
 * Reusable piece of query to avoid repetition in `previewEntryCollectionQuery`
 */
const previewLinkedFrom = `
  linkedFrom(allowedLocales: ["en"]) {
    pageCollection(limit: 1) {
      items {
        sys {
          id
        }
        slug
        linkedFrom(allowedLocales: ["en"]) {
          pageCollection(limit: 1) {
            items {
              sys {
                id
              }
              slug
              linkedFrom(allowedLocales: ["en"]) {
                pageCollection(limit: 1) {
                  items {
                    sys {
                      id
                    }
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * Query to get entries referenced by the `preview` page
 * @param {String} contentType - CMS contentType id
 * @return {String} graphql query
 */
export const previewEntryCollectionQuery = (contentType: string, preview: boolean) =>
  gql`
    query entryQuery($id: String!, $locale: String!) {
      entryCollection(limit: 1, locale: $locale, where: {sys: {id: $id}}, preview: ${preview}) {
        items {
          sys {
            id
          }
          contentType: __typename
          ... on ${contentType.charAt(0).toUpperCase()}${contentType.substring(1)} {
            ${previewLinkedFrom}
          }
        }
      }
    }
  `

/**
 * Query to get entries referenced inside Rich Text
 */
export const richTextEntryCollectionQuery = ({ preview }: { preview: boolean }) => {
  return gql`
  ${fragmentModuleTable}
  ${fragmentModuleMedia}
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  ${fragmentBlockFaqGroup}
  ${fragmentBlockQuote}
  ${fragmentModuleNavigationLink}
  ${fragmentModuleCodeSnippet}
  ${fragmentPageKnowledgeHubDetail}
  ${fragmentBlockCallToActionWithMedia}
  query entryQuery($id: String!, $locale: String!) {
    entryCollection(limit: 1, where: {sys: {id: $id}}, locale: $locale, preview: ${preview}) {
      items {
        sys {
          id
        }
        contentType: __typename
        ... on ModuleTable {
          ...ModuleTableFragment
        }
        ... on ModuleMedia {
          ...ModuleMediaFragment
        }
        ... on ModuleVideo {
          ...ModuleVideoFragment
        }
        ... on ModuleInternalVideo {
          ...ModuleInternalVideoFragment
        }
        ... on ModuleNavigationLink {
          ...ModuleNavigationLinkFragment
        }
        ... on ModuleChapter {
          title
        }
        ... on ModuleCodeSnippet {
          ...ModuleCodeSnippetFragment
        }
        ... on PageKnowledgeHubDetail {
          ...PageKnowledgeHubDetailFragment
        }
        ... on BlockFaqGroup {
          ...BlockFaqGroupFragment
        }
        ... on BlockQuote {
          ...BlockQuoteFragment
        }
        ... on BlockCallToActionWithMedia {
          ...BlockCallToActionWithMediaFragment
        }
      }
    }
  }
`
}

// Preview query

const queryStringAllLocales = (id, contentType = '', fields = [], locales: any[] = []) =>
  locales.reduce((acc, locale) => {
    return `${acc}${locale.code}: ${contentType}(id: "${id}", preview: true, locale: "${locale.iso}") {
      slug
      ${fields.join(' ')}
    }`
  }, '')

export const previewContentTypeQuery = (id, contentType = '', fields = [], locales: any[] = []) =>
  gql`
    query preview {
      ${queryStringAllLocales(id, contentType, fields, locales)}
    }
  `
